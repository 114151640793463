.home_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader_padding {
  height: 100vh;
  display: flex;
  align-items: center;
}

.hive_tabs_container {
  margin: 20px 0px;
}
