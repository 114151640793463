@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@400;500;600;700&display=swap");

:where(*, *::after, *::before) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans Condensed", sans-serif;
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

link[rel="icon"] {
  border-radius: 9999px;
}

/* Hide scrollbar for specific element */
.element-class::-webkit-scrollbar {
  display: none;
}

:where(html) {
  //color-scheme: dark light;
  -webkit-text-size-adjust: none;
  font-size: 16px;
}

:where([hidden]) {
  display: none;
}

:where(hr) {
  border: 1px solid #c8c8c8;
}

@media (prefers-reduced-motion: no-preference) {
  :where(html:focus-within) {
    scroll-behavior: smooth;
  }
}

:where(body) {
  min-height: 100vh;
  font-family: "IBM Plex Sans Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
}

:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}

:where(textarea) {
  resize: vertical;
}

:where(button, label, select, summary, [role="button"], [role="option"]) {
  cursor: pointer;
}

:where(:disabled) {
  cursor: not-allowed;
}

:where(label:has(> input:disabled), label:has(+ input:disabled)) {
  cursor: not-allowed;
}

// :where(button) {
//   border-style: solid;
// }

:where(a) {
  text-decoration: none;
  text-underline-offset: 0.2ex;
}

:where(ul, ol) {
  list-style: none;
}

:where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(img, svg, picture) {
  max-width: 100%;
  height: auto;
}

:where(p, h1, h2, h3, h4, h5, h6) {
  overflow-wrap: break-word;
}

:where(h1, h2, h3) {
  line-height: calc(1em + 0.5rem);
}

:where(hr) {
  border-width: 1px 0 0 0;
  color: inherit;
  height: 0;
  overflow: visible;
}

:where(:focus-visible) {
  outline: 2px solid var(--focus-color, Highlight);
  outline-offset: 2px;
}
