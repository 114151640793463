.profile_user_detail_container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // text-align: left;
  justify-content: center;
  gap: 5px;
}

.profile_menu_content_wrapper {
  padding: 0px 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.social_link_edit_input_wrapper_underline {
  border-bottom: 2px solid #ccc;
  padding: 8px 5px;
}

.profile_logo {
  width: 62px;
  height: 62px;
  margin: 0px auto;
}

.account_padding {
  padding: 10px 14px;
}
