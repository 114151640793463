.profile_menu_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.vhive_org_btns {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // gap: 5px;
  text-align: center;
}

.font-ibm {
  font-family: "IBM Plex Sans", sans-serif;
}

.text-sm {
  font-size: 16px;
}

.text_xs {
  font-size: 14px;
}

.profile_hive_selector {
  margin: 10px 16px;
}

.pointer {
  cursor: pointer;
}

.selector:hover {
  color: black;
  font-weight: 500;
}
