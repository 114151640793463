.main_container {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #fff;
}

.profile_menu_container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.hive_logo {
  height: 40px;
  width: 40px;
  margin: 8px 10px;
  border-radius: 9999px;
}
