.App {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.home_container {
  background-color: #fff;
}

.honeycomb-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  margin-bottom: -5%;
}

.row:nth-child(2n) {
  padding-left: 10%;
}

.row:last-child {
  margin-bottom: 0;
}

svg {
  width: 150px;
  height: auto;
  margin: 0 2%;
}

.hex_item_wrapper {
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  // width: 150px;
  // height: auto;
  // margin: 0 2%;
}

.hex_item_wrapper:hover {
  transform: scale(1.2);
}

.hex_item_svg {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // right: 0;
  // left: 0;
}

.hex_item_content {
  position: absolute;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}

#canvas {
  display: block;
  z-index: 0;
  cursor: grab;
}

.hex_item_svg {
  background-color: transparent;
}

.hex-color-0 {
  stop-color: #fada5e;
  // background-color: #fada5e;
}

.hex-color-1 {
  stop-color: #800080;
  // background-color: #800080;
}

.hex-color-2 {
  stop-color: #89cff0;
  // background-color: #89cff0;
}

.hex-color-3 {
  stop-color: #20b2aa;
  // background-color: #20b2aa;
}

.hex-color-4 {
  stop-color: #ff7f50;
  // background-color: #ff7f50;
}

.black {
  color: #000;
}

.hive_hexagon_imgs {
  opacity: 0.8;
  width: 150px;
}

.hive_hexagon_content {
  z-index: 150;
}
